/* eslint-disable @next/next/no-img-element */
import {m} from 'framer-motion';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {IProduct} from '@/data/products';
import LocalesEnum from '@/enums/LocalesEnum';
import {useLocaleState} from '@/store/locale';
import Image from '@/UI/image';
import {animations} from '@/utils/animations';
import Specification from './specification';
import styles from './product.module.css';

type IProps = {
    data: IProduct[]
}

const Product: React.FC<IProps> = ({data}) => {
    const router = useRouter();
    const [{locale}] = useLocaleState();
    const lang = router.locale as LocalesEnum || LocalesEnum.cs;

    return (
        <m.div
            initial="initial"
            animate="enter"
            variants={animations.wrapper}
            className={styles.products}
        >
            {data.map(item => (
                <m.article
                    key={item.id}
                    variants={animations.item}
                    className={styles.product}
                    data-testid="component-product"
                >
                    <m.h2 variants={animations.subitem} className={styles.heading}>
                        <Link href={`${router.route}/${item.code}`}>
                            <a
                                href={`${router.route}/${item.code}`}
                                title={`${item.code.toUpperCase()} > ${locale.products[item.code].heading}`}
                            >
                                {item.code.toUpperCase()}
                            </a>
                        </Link>
                    </m.h2>
                    <m.h3 variants={animations.subitem} className={styles.subheading}>
                        <Link href={`${router.route}/${item.code}`}>
                            <a
                                href={`${router.route}/${item.code}`}
                                title={`${item.code.toUpperCase()} > ${locale.products[item.code].heading}`}
                            >
                                {locale.products[item.code].heading}
                            </a>
                        </Link>
                    </m.h3>
                    <m.p variants={animations.subitem} className={styles.description}>
                        {locale.products[item.code].description}
                    </m.p>
                    {item.purifier ? (
                        <div className={styles.section}>
                            <img
                                src="/icons/icon_air-purifier.svg"
                                alt=""
                                width={80}
                                height={80}
                                className={styles.icon}
                            />
                            <figure className={`${styles.figure} ${styles.purifier}`}>
                                <Link href={`${router.route}/${item.code}`}>
                                    <a
                                        href={`${router.route}/${item.code}`}
                                        title={`${item.code.toUpperCase()} > ${locale.products[item.code].heading}`}
                                        className={styles.link}
                                    >
                                        <Image
                                            preload
                                            src={`/images/${item.images.list.filename}`}
                                            type='webp, avif'
                                            width={item.images.list.width}
                                            height={item.images.list.height}
                                        />
                                    </a>
                                </Link>
                            </figure>
                        </div>
                    ) : (
                        <figure className={styles.figure}>
                            <Link href={`${router.route}/${item.code}`}>
                                <a
                                    href={`${router.route}/${item.code}`}
                                    title={`${item.code.toUpperCase()} > ${locale.products[item.code].heading}`}
                                    className={styles.link}
                                >
                                    <Image
                                        preload
                                        src={`/images/${item.images.list.filename}`}
                                        type='webp, avif'
                                        width={item.images.list.width}
                                        height={item.images.list.height}
                                    />
                                </a>
                            </Link>
                        </figure>
                    )}
                    <Specification
                        code={item.code}
                        colors={item.colors}
                        specification={item.specification[lang]}
                    />
                </m.article>
            ))}
        </m.div>
    );
};

export default Product;
