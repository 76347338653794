import Head from 'next/head';
import Loader from '../loader';
import styles from './image.module.css';

type IProps = {
    src: string
    width?: number
    height?: number
    alt?: string
    type?: 'webp' | 'avif' | 'jxl' | 'webp, avif' | 'webp, jxl' | 'avif, jxl' | 'webp, avif, jxl'
    loading?: 'lazy' | 'eager'
    preload?: boolean
    srcset?: string
    sizes?: string
    jxlSource?: string
    avifSource?: string
    webpSource?: string
    hasLoader?: boolean
    className?: string
}

const Image: React.FC<IProps> = ({
    src,
    width,
    height,
    alt = '',
    type,
    loading = 'lazy',
    preload = false,
    srcset,
    sizes,
    jxlSource,
    avifSource,
    webpSource,
    hasLoader = false,
    className = '',
}) => {
    const filename = src.replace(/\.gif|\.png|\.jp[e]?g/, '');
    const hasWebp = type?.includes('webp');
    const hasAvif = type?.includes('avif');
    const hasJxl = type?.includes('jxl');

    return (
        <>
            {preload && (
                <Head>
                    {hasAvif && (
                        <link
                            rel="preload"
                            as="image"
                            href={avifSource || `${filename}.avif`}
                            type="image/avif"
                            data-testid="component-preload-avif"
                        />
                    )}

                    {hasWebp && !hasAvif && (
                        <link
                            rel="preload"
                            as="image"
                            href={webpSource || `${filename}.webp`}
                            type="image/webp"
                            data-testid="component-preload-webp"
                        />
                    )}
                </Head>
            )}

            <picture className={`${styles.picture} ${className}`} data-testid="component-image">
                {hasJxl && (
                    <source
                        srcSet={jxlSource || `${srcset ? srcset?.replace(/\.gif|\.png|\.jp[e]?g/g, '.jxl') : `${filename}.jxl`}`}
                        type="image/jxl"
                        data-testid="component-image-jxl"
                    />
                )}

                {hasAvif && (
                    <source
                        srcSet={avifSource || `${srcset ? srcset?.replace(/\.gif|\.png|\.jp[e]?g/g, '.avif') : `${filename}.avif`}`} type="image/avif"
                        data-testid="component-image-avif"
                    />
                )}

                {hasWebp && (
                    <source
                        srcSet={webpSource || `${srcset ? srcset?.replace(/\.gif|\.png|\.jp[e]?g/g, '.webp') : `${filename}.webp`}`}
                        type="image/webp"
                        data-testid="component-image-webp"
                    />
                )}

                <img
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    loading={loading}
                    decoding="async"
                    srcSet={srcset}
                    sizes={sizes}
                    className={`${styles.image} ${hasLoader ? styles.hasLoader : ''}`}
                />

                {hasLoader && (
                    <Loader className={styles.loader} />
                )}
            </picture>
        </>
    );
};

export default Image;
