import {GetStaticProps} from 'next';
import React from 'react';
import Layout from '@/components/layout';
import Product from '@/components/product';
import {ILocale} from '@/data/locales';
import products, {IProduct} from '@/data/products';
import LocalesEnum from '@/enums/LocalesEnum';
import {useLocaleState} from '@/store/locale';

type IProps = {
    data: IProduct[]
    locale: ILocale
}

const Page: React.FC<IProps> = ({data, locale}) => {
    const [{locale: locales}, {setLocale}] = useLocaleState();

    React.useEffect(() => {
        if (locales !== locale) {
            setLocale({locale});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return (
        <Layout hasFooter label={locale.pages.products.label}>
            <Product data={data} />
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = async context => {
    let locale;

    if (context.locale !== LocalesEnum.cs && context.locale !== LocalesEnum.pl) {
        return {notFound: true};
    }

    try {
        locale = (await import(`@/data/locales/${context.locale}`)).default;
    } catch (error) {
        return {notFound: true};
    }

    return ({
        props: {
            data: Object.values(products),
            locale,
        },
    });
};

export default Page;
