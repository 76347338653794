import {useRouter} from 'next/router';
import colors from '@/data/colors';
import ColorsEnum from '@/enums/ColorsEnum';
import ProductsEnum from '@/enums/ProductsEnum';
import {useLocaleState} from '@/store/locale';
import Anchor from '@/UI/anchor';
import styles from './specification.module.css';

type IProps = {
    code: ProductsEnum
    colors: string[]
    files?: string[]
    specification?: string
}

const Specification: React.FC<IProps> = ({...props}) => {
    const [{locale}] = useLocaleState();
    const router = useRouter();
    const isDetail = router.query.code === props.code;

    return (
        <>
            <section
                className={`${styles.specification} ${isDetail ? styles.detail : ''}`} data-testid="component-specification"
            >
                <div className={styles.key}>
                    {locale.product.shade}:
                </div>
                <div className={styles.value}>
                    {props.colors.length > 0 ? (
                        <ul className={styles.list}>
                            {(props.colors as ColorsEnum[]).map(color => (
                                <li key={color} className={styles.item}>
                                    <i
                                        style={{background: colors[color]}}
                                        className={styles.color}
                                    >
                                        <span className={styles.tooltip}>{locale.colors[color]}</span>
                                    </i>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>{locale.emptyColor}</p>
                    )}
                </div>
            </section>
            {props.specification && (
                <section className={`${styles.specification} ${isDetail ? styles.detail : ''}`}>
                    <div className={styles.key}>
                        {locale.product.specification.title}:
                    </div>
                    <div className={styles.value}>
                        <Anchor
                            href={`/docs/${props.specification}`}
                            title={`${props.code.toUpperCase()} > ${locale.products[props.code].heading}`}
                            className={styles.anchor}
                            target="_blank"
                        >
                            {locale.product.specification.link}
                        </Anchor>
                    </div>
                </section>
            )}
            {isDetail && props.files?.length && (
                <section className={`${styles.specification} ${isDetail ? styles.detail : ''}`}>
                    <div className={styles.key}>
                        {locale.product.models.title}:
                    </div>
                    <div className={styles.value}>
                        <ul>
                            {props.files.map((file, iterator) => (
                                <li key={file}>
                                    <Anchor
                                        href={`/zip/${file}`}
                                        title={`${props.code.toUpperCase()} > ${locale.products[props.code].heading}`}
                                        target="_blank"
                                        className={styles.anchor}
                                    >
                                        {locale.product.models.files[iterator]}
                                    </Anchor>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            )}
        </>
    );
};

export default Specification;
