import ColorsEnum from '@/enums/ColorsEnum';

type IColors = {
    [key in ColorsEnum]: string
}

const colors: IColors = {
    [ColorsEnum.RAL]: 'linear-gradient(-91deg, #ff0000 0%, #ff00ff 15%, #0000ff 33%, #00ffff 49%, #00ff00 67%, #ffff00 84%, #ff0000 100%)',
    [ColorsEnum.RAL1006]: '#fbb938',
    [ColorsEnum.RAL1013]: '#e9e6d8',
    [ColorsEnum.RAL1028]: '#f6d052',
    [ColorsEnum.RAL3002]: '#d23d2b',
    [ColorsEnum.RAL3020]: '#e32e41',
    [ColorsEnum.RAL7022]: '#4d545c',
    [ColorsEnum.RAL7026]: '#475256',
    [ColorsEnum.RAL7047]: '#b7b7b6',
    [ColorsEnum.RAL9003]: '#faf7ef',
    [ColorsEnum.MAPLE01]: '#acb292',
    [ColorsEnum.MAPLE02]: '#bdbd6a',
    [ColorsEnum.MAPLE03]: '#8c5842',
    [ColorsEnum.OAK01]: '#898678',
    [ColorsEnum.OAK02]: '#9b8d5e',
    [ColorsEnum.OAK03]: '#454745',
    [ColorsEnum.WALNUT01]: '#645e58',
    [ColorsEnum.WALNUT02]: '#554a44',
    [ColorsEnum.WALNUT03]: '#525852',
    [ColorsEnum.Arabescato]: 'url(/materials/arabescato.png)',
    [ColorsEnum.BrecciaSarda]: 'url(/materials/bresccia-sarda.png)',
    [ColorsEnum.Labradorit]: 'url(/materials/labradorit.png)',
    [ColorsEnum.NeroMarquina]: 'url(/materials/nero-marquina.png)',
    [ColorsEnum.ParadisoBrown]: 'url(/materials/paradiso-brown.png)',
    [ColorsEnum.Superbo]: 'url(/materials/superbo.png)',
    [ColorsEnum.VerdeGuatemala]: 'url(/materials/verde-guatemala.png)',
    [ColorsEnum.VerdeTinos]: 'url(/materials/verde-tinos.png)',
};

export default colors;
